/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, PriceWrap, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1auwki2 --style2 --full pb--60 pt--60" name={"cyfs9lw05h4"} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/5e072fe6f5c3468fbd9c811a789ffca9_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/5e072fe6f5c3468fbd9c811a789ffca9_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/5e072fe6f5c3468fbd9c811a789ffca9_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/5e072fe6f5c3468fbd9c811a789ffca9_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/5e072fe6f5c3468fbd9c811a789ffca9_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/5e072fe6f5c3468fbd9c811a789ffca9_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/5e072fe6f5c3468fbd9c811a789ffca9_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/5e072fe6f5c3468fbd9c811a789ffca9_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"<span style=\"color: var(--white)\">Kontakt</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"rv50mxf9yy"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/645594cbdb684ca595f7a2e1693ca216_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/18947/645594cbdb684ca595f7a2e1693ca216_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/645594cbdb684ca595f7a2e1693ca216_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/645594cbdb684ca595f7a2e1693ca216_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/645594cbdb684ca595f7a2e1693ca216_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box fs--36" content={"Nová budova Národního muzea<br>"}>
              </Title>

              <Text className="text-box ff--1 fs--24" style={{"maxWidth":467}} content={"Bistro Národní muzeum<br><br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":467}} content={"Adresa: Vinohradská 1, 110 00 Praha"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":467}} content={"Telefon: +420 257 310 852"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":467}} content={""}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/377d93e4a0d946c0b7a3925d48e9b5aa_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/18947/377d93e4a0d946c0b7a3925d48e9b5aa_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/377d93e4a0d946c0b7a3925d48e9b5aa_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/377d93e4a0d946c0b7a3925d48e9b5aa_s=860x_.png 860w"} position={null}>
              </Image>

              <Title className="title-box fs--36" content={"Historická budova Národního muzea"}>
              </Title>

              <Text className="text-box ff--1 fs--24" style={{"maxWidth":467}} content={"Kavárna<br>Chill out zóna"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":467}} content={"Adresa:&nbsp;Václavské náměstí 68, 110 00 Praha"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":467}} content={"Telefon: +420 220 960 458\n"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":467}} content={"<span style=\"font-weight: bold; color: var(--black);\"><a href=\"https://cdn.swbpg.com/d/18947/20220915-bnm-menu-all-shops-web-dragged.pdf\" target=\"_blank\">MENU</a></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"6p36k6tai4k"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43" content={"Royal Catering s.r.o."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgb(0, 0, 0);\">&nbsp;Rybná 716/24<br>Staré Město<br>110 00 Praha<br><br>IČO: 06722857<br>DIČ: CZ06722857<br><br><span style=\"font-weight: bold;\">Kontaktní osoba<br></span>Tomáš Bláha: +420 723 101 012<br>Jan Zeman:  +420 777 009 561<br>Email: info@bistronarodnimuzeum.cz</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-gqd6tz pb--60 pt--60" css={css`
      
    background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <PriceWrap className="el--1" style={{"backgroundColor":"#f4f4f4"}} columns={"1"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box text-box--center ff--2 fs--8 w--500" content={"<br>"}>
                  </Text>

                  <Text className="text-box text-box--center ff--2 fs--18 w--500" content={"<span style=\"font-weight: bold; color: rgb(0, 0, 0);\">Nová budova Národního muzea</span>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" style={{"maxWidth":392}} content={"<span style=\"font-weight: bold;\">PO - NE</span>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" style={{"maxWidth":392}} content={"9:30 - 19:00"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" style={{"maxWidth":392}} content={"<br>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" style={{"maxWidth":392}} content={"Telefon: +420 257 310 852<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap className="el--1" style={{"backgroundColor":"#f4f4f4"}} columns={"1"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box text-box--center fs--36" content={"<br>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" content={"<span style=\"color: rgb(0, 0, 0);\">Telefonické objednávky od 10:00 – 15:00 na telefonu: +420 257 310 852&nbsp;</span><br>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--30" content={"<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap className="el--1" style={{"backgroundColor":"#f4f4f4"}} columns={"1"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box text-box--center fs--8" content={"<br>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" content={"<span style=\"font-weight: bold; color: rgb(0, 0, 0);\">Historická budova Národního muzea</span><br>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18 pb--02" content={"<span style=\"font-weight: bold;\">PO - NE</span><br>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18 pb--02" content={"10:00 - 18:00<br>"}>
                  </Text>

                  <Text className="text-box fs--8 pb--02" content={"<br>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18 pb--02" content={"Telefon: +420 220 960 458<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--20 pb--60 pl--20 pr--20 pt--60">
          
          <ColumnWrap className="column__flex el--4 pl--0 pr--0 flex--top" columns={"4"}>
            
            <ColumnWrapper className="pl--20 pr--20">
              
              <Image className="--left" style={{"maxWidth":192}} alt={""} src={"https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Subtitle className="subtitle-box subtitle-box--center mt--02" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(0, 0, 0);\">E-mail</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"info@bistronarodnimuzeum.cz"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Subtitle className="subtitle-box subtitle-box--center mt--02" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(0, 0, 0);\">Telefon</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"+420 257 310 852"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Subtitle className="subtitle-box subtitle-box--center mt--02" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(0, 0, 0);\">Sledujte nás</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"<a href=\"https://www.facebook.com/bistronarodnimuzeum\" target=\"_blank\">Facebook</a>"}>
              </Text>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"<a href=\"https://www.instagram.com/bistronarodnimuzeum/\" target=\"_blank\">Instagram</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}